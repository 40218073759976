import { isServer } from "~/utils/env_check";

const buildScript = (src: string, attributes: Record<string, string> = {}, onload?: () => void) =>
  new Promise<boolean>((resolve, reject) => {
    if (isServer()) return;

    if (document.querySelector(`script[src="${src}"]`)) return resolve(true);

    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = src;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      onload?.();
      resolve(true);
    };
    script.onerror = () => {
      reject(false);
    };

    Object.entries(attributes).forEach(([key, value]) => {
      script.setAttribute(key, value);
    });

    document.head.appendChild(script);
  });

export default buildScript;
